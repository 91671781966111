<template>
  <h2 class="recovery-header">
    {{ t('Відновлення паролю') }}
  </h2>
  <div class="flex h-5/6">
    <form
      id="password-recovery-form"
      :key="key"
      v-loading="isLoading"
      @submit.prevent="submit"
    >
      <span class="form-title">
        {{ t('reset_password_description') }}
      </span>
      <div>
        <AppFormInput
          id="login-input"
          v-model="loginForm.login"
          name="login"
          type="text"
          :placeholder="t('Email або телефон') + '*'"
          :error="errors.login"
          input-class="h-12"
        />
      </div>
      <button
        type="submit"
        class="submit-button"
        :inert="isLoading"
        :aria-label="t('Відновити пароль')"
      >
        {{ t('Відновити пароль') }}
      </button>
    </form>
  </div>
</template>

<script setup>
import { isValidEmail, isValidPhone } from '~~/utils/helpers/validate'
import { useAuthStore } from '~/stores/auth'

const props = defineProps({
  isOnPage: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits(['successRecovery'])
const { $api, $toast } = useNuxtApp()
const { t } = useI18n()
const goTo = inject('goTo')

const loginForm = reactive({
  login: ''
})

const errors = reactive({
  login: null
})
const isLoading = ref(false)
const key = ref(null)

const cleanState = () => {
  errors.login = null
}

const isValidLogin = login => {
  return isValidPhone(login) || isValidEmail(login)
}

const resetPasswordRequest = async () => {
  isLoading.value = true
  try {
    await $api.auth.resetPasswordRequest(loginForm.login, {
      onSuccess: () => {
        cleanState()
        if (!props.isOnPage) {
          goTo('auth')
        }
      },
      onFinally: res => {
        isLoading.value = false
        if (res.code === 200) {
          $toast.success(res.message)
          useAuthStore().hideAuthDialog()
          if (props.isOnPage) {
            emit('successRecovery')
          }
        } else {
          $toast.error(res.message)
        }
      }
    })
  } catch (e) {
    console.error('error', e)
  }
}

const submit = () => {
  if (!isValidLogin(loginForm.login)) {
    errors.login = t('incorrect_email_phone_error')
    return false
  }

  resetPasswordRequest()
}

watch(
  () => isLoading.value,
  v => !v && (key.value = Date.now().toString())
)
</script>

<style lang="postcss" scoped>
.recovery-header {
  @apply text-xl font-bold mb-4;
}
#password-recovery-form {
  @apply flex flex-col gap-4 w-full max-w-sm md:max-w-none md:w-[367px] m-auto;
}
.form-title {
  @apply text-xs text-center md:text-left;
}
.action-button {
  @apply text-xs text-orange underline cursor-pointer;
}
.submit-button {
  @apply btn btn-primary md:w-64 uppercase text-base w-full;
}
</style>
