<template>
  <TransitionRoot as="template" :show="props.open">
    <Dialog as="div" class="relative z-10" @close="emit('close')">
      <AppDialogsCommonBackdrop />

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div
          class="flex md:min-h-fit min-h-full justify-center text-center p-0"
        >
          <AppDialogsCommonPanelTransition>
            <DialogPanel
              class="transform rounded-0 bg-white p-4 my-0 text-left transition-all min-h-full w-full relative md:my-24 md:rounded-md md:w-fit md:px-10 md:py-9"
            >
              <AppDialogsCommonCloseButton
                class="absolute right-5 top-5 md:right-[31px] md:top-[30px]"
                @click="emit('close')"
              />
              <slot></slot>
            </DialogPanel>
          </AppDialogsCommonPanelTransition>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { Dialog, DialogPanel, TransitionRoot } from '@headlessui/vue'

const props = defineProps({
  open: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits(['close'])
</script>
