<template>
  <div class="sign-in-container">
    <form id="sign-in-form" @submit.prevent="submit">
      <span class="text-xs md:text-sm">{{
        t('Введіть телефон або e-mail')
      }}</span>
      <AppFormInput
        id="username-input"
        v-model="form.username"
        name="username"
        type="text"
        :placeholder="t('Логін')"
        required
        input-class="h-12 md:h-[42px]"
        :error="!!errorMessage"
      />
      <AppFormInput
        id="password-input"
        v-model="form.password"
        name="password"
        type="password"
        :placeholder="t('Пароль')"
        required
        input-class="h-12 md:h-[42px]"
        :error="errorMessage"
      />
      <div class="flex leading-4 items-center">
        <AppFormCheckbox
          v-model="isRemember"
          name="remember-me"
          :label="t('Запамʼятати мене')"
          label-class="text-xs"
        />
        <span
          class="forgot-password"
          @click="
            props.isOnPage
              ? openForgotPasswordModal()
              : goTo('passwordRecovery')
          "
        >
          {{ t('Забули пароль?') }}
        </span>
      </div>
      <button type="submit" class="submit-button" aria-label="Увійти">
        {{ t('Увійти') }}
      </button>
    </form>

    <div class="middle-section">
      <span class="my-0 md:my-auto">{{ t('або') }}</span>
    </div>

    <div class="socials-section">
      <span class="socials-section-title">{{
        t('Увійти через соц мережі')
      }}</span>
      <div class="socials-section-buttons">
        <AppSocialsGoogleSignInButton />
        <AppSocialsFacebookSignInButton />
      </div>
    </div>
    <AppDialogsAuthDialogWrapper
      :open="isPasswordRecoveryOpen"
      @close="hidePasswordRecoveryModal"
    >
      <AppDialogsAuthPasswordRecovery
        :is-on-page="props.isOnPage"
        @success-recovery="hidePasswordRecoveryModal"
      />
    </AppDialogsAuthDialogWrapper>
  </div>
</template>

<script setup>
import { useAuthStore } from '~~/stores/auth'
const props = defineProps({
  redirect: {
    type: Boolean,
    default: true
  },
  isOnPage: {
    type: Boolean,
    default: false
  }
})
const { t } = useI18n()
const loading = inject('loading')
const authStore = useAuthStore()
const goTo = inject('goTo')
const form = reactive({
  username: '',
  password: ''
})
const errorMessage = ref(null)
const isRemember = ref(false)
provide('isRemember', isRemember)
provide(
  'redirect',
  computed(() => props.redirect)
)

const isPasswordRecoveryOpen = ref(false)

const openForgotPasswordModal = () => {
  console.log('open work')
  isPasswordRecoveryOpen.value = true
}

const hidePasswordRecoveryModal = () => {
  isPasswordRecoveryOpen.value = false
}

const submit = () => {
  loading.value = true
  const options = {
    redirect: props.redirect,
    isRemember: isRemember.value,
    onSuccess: () => (errorMessage.value = null),
    onError: error => (errorMessage.value = error),
    onFinally: () => (loading.value = false)
  }
  if (useRoute()?.query?.login === 'store') {
    return authStore.loginStore(
      {
        email: form.username,
        password: form.password
      },
      options
    )
  }
  return authStore.login(form, options)
}
watch(form, () => errorMessage.value && (errorMessage.value = null))
</script>

<style lang="postcss" scoped>
.sign-in-container {
  @apply flex gap-4 flex-col items-center md:gap-[30px] md:flex-row md:items-stretch w-full max-w-sm md:max-w-none md:w-fit mx-auto md:mx-0;
}
#sign-in-form {
  @apply flex flex-col gap-4 w-full md:w-[266px];
}
.submit-button {
  @apply btn btn-primary w-full uppercase text-xs md:w-[118px];
}
.forgot-password {
  @apply text-xs underline ml-auto cursor-pointer;
}
.middle-section {
  @apply flex md:h-40 h-fit text-xs md:text-sm;
}
.socials-section {
  @apply flex flex-col w-full md:w-48;
}
.socials-section-title {
  @apply text-xs text-center mb-4 md:text-sm;
}
.socials-section-buttons {
  @apply flex flex-row gap-4 md:flex-col;
}
</style>
